.quiz-questions-container {
  overflow: hidden;
  width: 100vw;
  height: 92vh;
  position: relative;
}

.quiz-cards-container {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
