.quiz-questions-wrapper {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.quiz-questions-wrapper p {
  color: white;
  padding: 0 3rem;
  margin: 30px auto 30px auto;
  text-align: center;
  font-size: 20px;
}

.control-container {
  width: 100%;
}

.control-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 2rem;
  /* margin-bottom: 70px; */
}

.true-button,
.false-button {
  margin-top: 50px;
  padding: 15px 40px 14px;
  border-radius: 40px;
  border: solid 2px #fff;
  background-color: transparent;
  color: white;
  cursor: pointer;
}

.cards-wrapper {
  width: 650px;
  display: flex;
  height: 357px;
  flex-direction: column;
  min-height: 357px;
  position: relative;
}

.card-1 {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  transform: translateY(-800px) rotate(-60deg);
  animation: rotateCard1 0.5s linear 0.2s 1 forwards;
  opacity: 0;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-2 {
  position: absolute;
  z-index: 4;
  margin-top: 35px;
  width: 100%;
  height: 100%;
  transform: translateY(-100px) rotate(30deg);
  animation: rotateCard2 0.5s linear 0.4s 1 forwards;
  opacity: 0;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-3 {
  z-index: 3;
  position: absolute;
  top: 70px;
  transform: translateY(-600px) rotate(-60deg);
  animation: rotateCard3 0.5s linear 0.6s 1 forwards;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-4 {
  z-index: 2;
  transform: scale(0.7);
  position: absolute;
  top: 105px;
  transform: translateY(-400px) rotate(30deg) scale(0.9);
  animation: rotateCard4 0.5s linear 0.7s 1 forwards;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-5 {
  z-index: 1;
  transform: scale(0.6);
  position: absolute;
  top: 140px;
  transform: translateY(-400px) rotate(30deg) scale(0.8);
  animation: rotateCard5 0.5s linear 0.7s 1 forwards;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.hide-wrong-choice {
  transform: none;
  animation: hideWrongChoice 1.2s linear forwards;
}

.hide-correct-choice {
  transform: none;
  animation: hideCorrectChoice 1.2s linear forwards;
}

.scale-to-100 {
  opacity: 1;
  transform: scale(0.86);
  animation: scaleTo100 0.3s linear 0.2s forwards;
}

.scale-to-90 {
  opacity: 1;
  transform: scale(0.76);
  animation: scaleTo90 0.3s linear 0.2s forwards;
}

.scale-to-80 {
  opacity: 1;
  transform: scale(0.66);
  animation: scaleTo80 0.3s linear 0.2s forwards;
}

.scale-to-70 {
  opacity: 1;
  transform: scale(0.56);
  animation: scaleTo70 0.3s linear 0.2s forwards;
}

@keyframes rotateCard1 {
  25% {
    transform: translateY(-600px) rotate(-45deg);
    background-color: rgba (255, 255, 255, 0.7);
    opacity: 0.25;
  }
  50% {
    transform: translateY(-400px rotate(-30deg));
    background-color: rgba (255, 255, 255, 0.8);
    opacity: 0.5;
  }

  75% {
    transform: translateY(-200px) rotate(-15deg);
    background-color: rgba (255, 255, 255, 0.9);
    opacity: 0.75;
  }

  100% {
    transform: translateY(0px) rotate(0deg);
    background-color: rgba (255, 255, 255, 1);
    opacity: 1;
  }
}

@keyframes rotateCard2 {
  25% {
    transform: translateY(-75px) rotate(20deg) scale(0.99);
    background-color: rgba (255, 255, 255, 0.5);
    opacity: 0.25;
  }
  50% {
    transform: translateY(-50px) rotate(13deg) scale(0.96);
    background-color: rgba (255, 255, 255, 0.6);
    opacity: 0.5;
  }

  75% {
    transform: translateY(-25px) rotate(5deg) scale(0.93);
    background-color: rgba (255, 255, 255, 0.9);
    opacity: 0.75;
  }

  100% {
    transform: translateY(0px) rotate(0deg) scale(0.9);
    background-color: rgba (255, 255, 255, 1);
    opacity: 1;
  }
}

@keyframes rotateCard3 {
  25% {
    transform: translateY(-450px) rotate(-45deg) scale(0.95);
    background-color: rgba (255, 255, 255, 0.4);
    opacity: 0.25;
  }
  50% {
    transform: translateY(-300px rotate(-30deg)) scale(0.9);
    background-color: rgba (255, 255, 255, 0.6);
    opacity: 0.5;
  }

  75% {
    transform: translateY(-150px) rotate(-15deg) scale(0.85);
    background-color: rgba (255, 255, 255, 0.8);
    opacity: 0.75;
  }

  100% {
    transform: translateY(0px) rotate(0deg) scale(0.8);
    background-color: rgba (255, 255, 255, 1);
    opacity: 1;
  }
}

@keyframes rotateCard4 {
  25% {
    transform: translateY(-300px) rotate(20deg) scale(0.85);
    background-color: rgba (255, 255, 255, 0.5);
    opacity: 0.25;
  }
  50% {
    transform: translateY(-200px) rotate(13deg) scale(0.8);
    background-color: rgba (255, 255, 255, 0.7);
    opacity: 0.5;
  }

  75% {
    transform: translateY(-100px) rotate(5deg) scale(0.75);
    background-color: rgba (255, 255, 255, 0.9);
    opacity: 0.75;
  }

  100% {
    transform: translateY(0px) rotate(0deg) scale(0.7);
    background-color: rgba (255, 255, 255, 1);
    opacity: 1;
  }
}
@keyframes rotateCard5 {
  25% {
    transform: translateY(-450px) rotate(-45deg) scale(0.75);
    background-color: rgba (255, 255, 255, 0.4);
    opacity: 0.25;
  }
  50% {
    transform: translateY(-300px rotate(-30deg)) scale(0.7);
    background-color: rgba (255, 255, 255, 0.6);
    opacity: 0.5;
  }

  75% {
    transform: translateY(-150px) rotate(-15deg) scale(0.65);
    background-color: rgba (255, 255, 255, 0.8);
    opacity: 0.75;
  }

  100% {
    transform: translateY(0px) rotate(0deg) scale(0.6);
    background-color: rgba (255, 255, 255, 1);
    opacity: 1;
  }
}

@keyframes hideWrongChoice {
  50% {
    opacity: 0.8;
    transform: translateX(-170px) rotate(-30deg) scale(0.9);
  }
  100% {
    transform: translateX(-200px) rotate(-35deg) scale(0.9);
    opacity: 0;
  }
}

@keyframes hideCorrectChoice {
  50% {
    opacity: 0.8;
    transform: translateX(170px) rotate(30deg) scale(0.9);
  }
  100% {
    transform: translateX(200px) rotate(35deg) scale(0.9);
    opacity: 0;
  }
}

@keyframes scaleTo100 {
  50% {
    transform: translateY(-25px) scale(0.93);
  }
  100% {
    transform: translateY(-45px) scale(0.96);
  }
}

@keyframes scaleTo90 {
  50% {
    transform: translateY(-25px) scale(0.83);
  }
  100% {
    transform: translateY(-45px) scale(0.86);
  }
}

@keyframes scaleTo80 {
  50% {
    transform: translateY(-25px) scale(0.73);
  }
  100% {
    transform: translateY(-45px) scale(0.76);
  }
}

@keyframes scaleTo70 {
  50% {
    transform: translateY(-25px) scale(0.63);
  }
  100% {
    transform: translateY(-45px) scale(0.66);
  }
}

@media (max-width: 850px) {
  .cards-wrapper {
    width: 90%;
    display: flex;
    height: 357px;
    flex-direction: column;
    min-height: 357px;
    position: relative;
  }
}

@media (max-width: 450px) {
  .cards-wrapper {
    height: 307px;
    min-height: 307px;
  }
}

@media (max-height: 750px) {
  .cards-wrapper {
    height: 300px;
    min-height: 300px;
  }
}
