.carousel-container {
  height: 330px;
  width: 100%;
  margin: 0 auto;
}

.carousel-item {
  margin: 0 auto;
  background-color: white;
  border: 1px solid black;
  width: 90%;
  min-height: 300px;
  height: 300px;
  padding: 0 1rem;
  border-radius: 8px;
  /* padding: 0 40px; */
}

.carousel-item h6 {
  margin: 20px 0;
  text-transform: uppercase;
  font-family: FatFrank-Free;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  text-align: left;
  color: #0e8476;
}

.slick-dots li button:before {
  margin-top: 10px !important;
  font-size: 14px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #0e8476 !important;
  height: 32px !important;
  width: 32px !important;
}

.slick-dots li button:before {
  color: white !important;
  height: 32px !important;
  width: 32px !important;
  opacity: 1 !important;
}

.carousel-item h4 {
  margin: 10px 0;
  font-family: FFNort-Regular;
  width: 95%;
  font-size: 20.8px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.32;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1a1346;
}

.carousel-item p {
  margin: 20px 0;
  font-family: FFNort-Light;
  width: 95%;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: 0.5px;
  text-align: left;
  color: #333;
}

@media (max-width: 1080px) {
  .carousel-item {
    width: 85%;
  }
}

@media (max-width: 700px) {
  .carousel-item {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 1200px) {
  .carousel-item h4 {
    font-size: clamp(0.9rem, 6vw, 1.1rem);
  }

  .carousel-item p {
    width: 95%;
    font-size: clamp(0.7rem, 4vw, 1rem);
  }
}
